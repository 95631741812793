/* ======================  SCRIPTS ====================== */
/************  JQUERY EST DISPONIBLE ICI *****************/

    $('document').ready(setup);
    $(window).on('load', documentReady);

    function setup() {

    }

    function documentReady() {

        var equalHeights = function (cible) {
            var hauteur = 0;
            $(cible).each(function () {
                h = $(this).outerHeight();
                if (h > hauteur) {
                    hauteur = h;
                }
            });
            $(cible).each(function () {
                $(this).css("height", hauteur);
            });
        };

        var disableResize = function (cible) {
            $(cible).each(function () {
                $(this).removeAttr("style");
            });
        };

        equalHeights(".height-container");

        var counter_slick = 0,
            counter_unslick = 0;

        $(window).resize(function () {
            disableResize(".height-container");
            equalHeights(".height-container");
        });

        if($(".slider-recruitement").length){
            $(".slider-recruitement").slick({
                autoplay: true,
                dots: false,
                infinite: true,
                speed: 2000
            });
        } else {}

        if (window.matchMedia("(min-width: 1025px)").matches) {
            /* La largeur minimum de l'affichage est 1025px inclus */
            var timer;

            $(".nav-recruitment").on("mouseenter", "> ul > li ", function () {
                var that = this;
                timer = setTimeout(function () {
                    $(".sub-menu", that).slideDown();
                }, 500);
            }).on("mouseleave", "> ul > li ", function () {
                clearTimeout(timer);
                $(".sub-menu", this).slideUp();
            });
        } else {
            /* L'affichage est inférieur à 1025px de large */
            $(".nav-recruitment").on("click", "> ul > li > a", function (e) {
                e.preventDefault();
                if ($(this).hasClass("active")) {
                    $(this).removeClass("active");
                    $(this).siblings(".sub-nav").slideUp();
                } else if (".nav-recruitment>ul>li>a") {
                    $(".nav-recruitment>ul>li>a").removeClass("active");
                    $(".nav-recruitment>ul>li>a").siblings(".sub-nav").slideUp();
                    $(this).addClass("active");
                    $(this).siblings(".sub-nav").slideDown();
                } else {
                    $(this).addClass("active");
                    $(this).siblings(".sub-nav").slideDown();
                }
            });
        }

        $(".btn-burger").click(showHideResponsiveMenu);
        function showHideResponsiveMenu(e) {
            e.preventDefault();
            if($(this).hasClass("active")){
                $(this).removeClass("active");
                $(".mobile-menu").removeClass("active");
            } else  {
                $(this).addClass("active");
                $(".mobile-menu").addClass("active");
            }
        }

    }